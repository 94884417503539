*{
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      outline: none;
}
body{
      background: url('images/background.jpg');
      background-position: center;
      background-attachment: fixed;
      background-size: cover;
      min-height:100vh;
      font-family: Arial, Helvetica, sans-serif;
      padding: 20px;
}
.Logo{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 10px;
}
.controller{
      margin-bottom: 50px;
      form{
            align-items: flex-end;
            display: flex;
            gap: 10px;
            button{
                  border: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 0 10px;
                  color: wheat;
                  background-color: #222;
                  cursor: pointer;
                  border-radius: 10px;
                  height: 40px;
            }
            section{
                  flex: 1;
                  span{
                        color: #fff;
                        margin-bottom: 10px;
                        font-size: 20px;
                        text-align: center;
                        display: block;
                        width: 100%;
                  }
                  input{
                        text-align: center;
                        height: 40px;
                        font-size: 20px;
                        border: 1px solid rgb(159, 159, 159);
                        border-radius: 10px;
                        width: 100%;
                  }
            }
      }
}
.floors{
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      .title{
            display: flex;
            gap: 20px;
            h2{
                  color: #fff;
                  text-align: center;
                  &:first-child{
                        width: 100px;
                  }
                  &:not(:first-child){
                        flex: 1;
                  }
            }
      }
}
.floor{
      display: flex;
      gap: 20px;
      width: 100%;
      section{
            text-align: center;
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgb(0, 110, 254);
            color: rgb(235, 233, 233);
            font-size: 30px;
            border-radius: 10px;
      }
      input{
            min-width: 20px;
            text-align: center;
            flex: 1;
            height: 40px;
            font-size: 20px;
            border: 1px solid rgb(159, 159, 159);
            border-radius: 10px;
            width: 100%;
            &:disabled{
            background-color: lightgray;
            }
      }
}